// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-gizlilik-politikasi-js": () => import("./../../../src/pages/gizlilik-politikasi.js" /* webpackChunkName: "component---src-pages-gizlilik-politikasi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-sartlar-ve-kosullar-js": () => import("./../../../src/pages/sartlar-ve-kosullar.js" /* webpackChunkName: "component---src-pages-sartlar-ve-kosullar-js" */)
}

